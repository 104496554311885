.dashboard-best-indicators {
    width: 100%;
    height: 740px;
    border-radius: 8px;
    margin-top: 26px;
}
.dash-best-block {
    position: absolute;
    border: 2px solid white;
    border-radius: 8px;
    padding: 16px;
}
.dash-best-label {
    position: absolute;
    bottom: 16px;

    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}

.dash-best-label-center {
    height: 30px;
    position: absolute;
    top: 50%;
    left: 16px;
    margin-top: -20px;
    font-weight: 500;
    font-size: 12px;
    line-height: 16px;
    color: #FFFFFF;
}



.dash-best-producttype-label {
    position: absolute;
    top: 16px;

    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.35px;
    color: #FFFFFF;
}

.dash-best-value-box {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 3px 8px;

    position: absolute;
    width: 70px;
    height: 30px;
    right: 16px;
    top: 16px;

    background: rgba(251, 251, 251, 0.2);
    border-radius: 100px;
}

.dash-best-value-box-center {
    width: 70px;
    height: 30px;
    position: absolute;
    top: 50%;
    right: 16px;
    margin-top: -20px;
    background: rgba(251, 251, 251, 0.2);
    border-radius: 100px;
}



.dash-best-value {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */

    letter-spacing: -0.08px;

    /* Grayscale/Extra White */

    color: #FFFFFF;
}

.dash-best-value-center {
    font-style: normal;
    font-weight: 600;
    font-size: 13px;
    line-height: 18px;
    /* identical to box height, or 138% */

    letter-spacing: -0.08px;

    /* Grayscale/Extra White */

    color: #FFFFFF;
}
.dash-best-label-analyst {
    font-style: normal;
    font-weight: 600;
    font-size: 17px;
    line-height: 22px;
    /* identical to box height, or 129% */

    letter-spacing: -0.41px;

    /* Grayscale/Extra White */

    color: #FFFFFF;
}

.dash-best-label-analyst-products {
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 133% */

    letter-spacing: -0.24px;

    /* Grayscale/Extra White */

    color: #FFFFFF;
}
.img-fluid {
    width: 100%;
    height: 100%;
    border-radius: 50%;
}

.dash-best-value-center-analyst {

}