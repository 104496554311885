.button-checkout {
    font-style: normal;
    font-weight: 600;
    font-size: 15px;
    line-height: 20px;
    margin-right: 16px;
    margin-top: 24px;

    letter-spacing: -0.24px;
    background: #FBFBFB;
    color: #6D7986;
    &__check {
        font-style: normal;
        font-weight: 600;
        font-size: 15px;
        line-height: 20px;
        margin-right: 16px;

        letter-spacing: -0.24px;
        background: #0B1F35;
        color: #FFFFFF;
    }
}

.dash-value {
    margin-top: 16px;
    margin-left: 16px;
}

.dash-value-back {
    display: inline-block;
    width: 56px;
    height:56px;
    border-radius: 100px;
    background:#FFFFFF;
    &__icon {
        margin-left: 15px;
        margin-top: 15px;
    }
}
.dash-total-number {
    display: inline-block;
    margin-left: 16px;
    vertical-align: text-top;

    font-style: normal;
    font-weight: bold;
    font-size: 34px;
    line-height: 41px;
    letter-spacing: 0.37px;
    color: #FFFFFF;
}
.dash-title {
    margin-top: 16px;
    margin-left: 16px;
    font-style: normal;
    font-weight: bold;
    font-size: 18px;
    line-height: 24px;
    /* identical to box height, or 133% */

    letter-spacing: 0.35px;
    /* Grayscale/Whitewash */
    color: #F3F4F5;
}
.dash-prev {
    margin-top: 16px;
    margin-left: 16px;
    height: 40px;
    width: 74px;
    border-radius: 100px;
    //padding-left: 16px;
    //padding-top: 10px;
    font-style: normal;
    font-weight: normal;
    font-size: 15px;
    line-height: 20px;
    /* identical to box height, or 133% */

    letter-spacing: -0.24px;
    /* Grayscale/Extra White */
    color: #FFFFFF;
}
.dash-prev-value {
    padding-left: 16px;
    padding-top: 10px;
}

.dash-prev__green {
    background: #2FC26E;
}
.dash-prev__red {
    background: #FF5C5C;
}

.dash-direction {
    display: inline-block;
    width: 40px;
    height:40px;
}

.dash-value-direction {
  color: #ffffff;
}

.poligon {
    z-index: 50;
}


.dashboard-title {
    margin-top: 40px;
    font-style: normal;
    font-weight: bold;
    font-size: 22px;
    line-height: 24px;
    letter-spacing: 0.35px;
    color: #000000;
}

.dashboard-total-plate {
    margin-top: 32px;
    width: 348px;
    height: 182px;
    background: #525E6C;
    border-radius: 8px;
    display: inline-block;
    margin-top: 20px;
    margin-right: 24px;

    &__numberPrev {
        margin-left: 50px;
        margin-top: 16px;
        padding-left: 20px;
        padding-top: 10px;

        width: 74px;
        height: 40px;
        left: 0px;
        top: 112px;

        background: #2FC26E;
        border-radius: 100px;

        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #FFFFFF;
    }
}
