@import '../../styles/variables';

.nav {

    transition: width 0.3s, left 0.3s;
    position: fixed;
    z-index: 400;
    width: 250px;
    top: 0;
    bottom: 0;
    left: 0;
    background-color: $dark_indigo;
    
    &__menu {
        height: 100%;
        width: 100%;

        .main-menu{
            overflow: hidden;
            font-size: 16px;
            color: $dark_indigo_05;
            list-style: none;
            margin-bottom: 90px;
            padding-top: 20px;

            &__item{
                overflow: hidden;
        
                &_sub-menu {
                    padding-left: 40px;
                    overflow: hidden;
                    max-height: 0;
    
                    a {
                        color: $dark_indigo_30;
                        display: block;
                        padding: 5px 10px;
                        
                        &:hover {
                            background: hsla(0,0%,100%,.1);
                        }
                    }
                    .active-sub {
                        color: #fff;
                    }
    
                    &.is-expanded {
                        max-height: 500px;
                        overflow: hidden;
                        p {
                          opacity: 1;
                        }
                    }
        
                }
                
                &_link{
                    padding: 10px 20px;
                    color: $dark_indigo_30;
                    display: block;
                    position: relative;
                    
                    &.active {
                        color: #fff;
                        background: hsla(0,0%,100%,.1);
                    }
        
                    &:hover {
                        color: #fff;
                        background: hsla(0,0%,100%,.1);
                    }
                
                }
    
                .dropdown-item {
                    padding: 10px 20px;
                    color: $dark_indigo_30;
                    display: block;
                    position: relative;
                    cursor: pointer;
                    position: relative;
    
                    &:hover {
                        color: #fff;
                        background: hsla(0,0%,100%,.1);
                    }
                    &.is-expanded {
                        &::after {
                            content: url('../../assets/icons/icon_arrow-down.svg');
                        }
                    }
                    &::after {
                        position: absolute;
                        top: 5px;
                        content: url('../../assets/icons/icon_arrow-up.svg');
                        font-size: 18px;
                        color: white;
                        position: absolute;
                        right: 30px;
                        font-family: monospace;
                      }
                }
    
            }
        
        }

        &_logo {
            width: 248px;
            transition: width 0.3s;
            position: fixed;
            bottom: 0;
            padding: 16px;
            background-color: $dark_indigo;
            box-shadow: inset 0 1px 0 0 hsla(0,0%,100%,.1);
            display: flex;
            align-items: center;
            line-height: 1;
            justify-content: center;
        }
    }
    
}

@media screen and (max-height: 910px) {
    .nav__menu {
        overflow-y: scroll;
    }
}