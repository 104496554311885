@import './variables';

*, *:after, *:before {
  box-sizing: border-box;
  padding: 0;
  margin: 0;
}

html {
  max-width: 1600px;
  min-width: 1600px;
  margin: auto;
}

body, html {
  //margin: 0;
  //padding: 0;
  height: 100%;
}

body {
  font-family: BlinkMacSystemFont, -apple-system, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', 'Roboto Rouble', sans-serif;
  font-size: 14px;
}

a {
  text-decoration: none;
}

#root {
  height: 100%;
}

.admin-main {
  content: "";
  display: table;
  clear: both;
}

.admin-main {
  margin: 0 auto;
  width: 100%;
  padding: 0;
  min-height: 100%;
  position: relative;

  &__menu {
    width: 250px;
    float: left;
    background-color: $dark_indigo;
    height: 100%;
    position: fixed;
    top: 0;
    left: 0;
    overflow: auto;

  }

  &__container {
    width: 87%;
    float: right;

    &_header {
      text-align: right;
      border-bottom: 1px solid $dark_indigo_05;
    }

    &_main {
      padding: 0 20px;
    }

    @media screen and (max-width: 1660px) {
      width: 84%;
    }
  }
}


.admin-left-menu, .admin-container {
  padding: 0;
}

.admin-spacer {
  display: block;
  width: 100%;
  height: 15px;
}

.separate {
  border-bottom: 1px solid rgba(240, 50, 38, .85);
}

.authors-list {
  padding-top: 5px;
  padding-bottom: 5px;
}

.authors-list.color,
.authors-list .label_theme_alfa-on-white,
.authors-list .link_theme_alfa-on-white {
  color: rgba(11, 31, 53, .6);
}

.authors-list:hover,
.authors-list:hover .label_theme_alfa-on-white,
.authors-list:hover .link_theme_alfa-on-white {
  color: rgba(11, 31, 53, 1);
}

.admin-image {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-end;
  img {
    max-width: 150px;
  }
  .image-button {
    display: none;
    position: absolute;
  }
}

.admin-image:hover {
  width: 100%;
  display: flex;
  position: relative;
  justify-content: flex-end;
  img {
    max-width: 150px;
  }
  .image-button {
    display: block;
    position: absolute;
  }
}

.admin-author .textarea__control {
  max-width: 1168px;
  float: right;
}

.admin-table-header {
  white-space: nowrap;
  border: 1px solid #dbdee1;
  margin: 0;
}

//.admin-table-header.catalog {
//  margin-right: 10%;
//}

.admin-table-body-data {
  align-items: center;
  white-space: nowrap;
  margin-right: 10%;
}

.admin-table-body-data:hover {
  background-color: #f3f4f5;
  cursor: pointer;
}

.admin-table-body-data.catalog {
  margin: 10px 0 10px 0px;
}

.admin_table_data_cell {
  justify-content: end;
  color: #546271;
  white-space: nowrap;
  padding: 10px 30px 10px 10px;
}

.border-link-bottom {
  border-color: rgba(11, 31, 53, .3);
  border-bottom-style: solid;
  border-bottom-width: 1px;
  transition: border-color 200ms ease-out;
}

.break_words_cell {
  white-space: normal;
}

.admin_table_header_cell {
  justify-content: end;
  color: #546271;
  white-space: nowrap;
  border-right: 1px solid #dbdee1;
  padding: 10px 30px 10px 10px;
}

.admin-table-row {
  margin: 10px 0;
  align-items: center;
}

.admin-table-row.catalog {
  margin: 10px 10% 10px 0px;
}

.admin-table-row:hover {
  background-color: #f3f4f5;
  cursor: pointer;
}

.admin-table-cell {
  padding: 10px 30px 10px 10px;
}

.admin-label-pl {
  padding-right: 10px;
}

.admin-table-cell-del {
  text-align: center;
  padding-right: 10px;
}

.checkbox__text {
  line-height: inherit;
}

.button-float {
  float: right;
  margin-bottom: 30px;
}

.mr20 {
  margin-right: 20px;
}

.field-info-6 {
  width: 49.999%;
  display: inline-block;
}

.delete-icon {
  cursor: pointer;
  text-align: center;
}

.col-11-field {
  max-width: 86.56%;
}

.col-11-calendar {
  max-width: 89.3%;
}

.col-6-selector {
  max-width: 78.3%;
}

.line-file {
  margin-right: 35px;
}

.break-all {
  word-break: break-all;
}

.list-none {
  list-style-type: none;
}

.mail-input {
  display: inline-block;
}

.mail-delete-icon {
  display: inline-block;
}

.magrin-mail {
  margin: 25px 0;
}

.label-email-list {
  width: 16.7%;
  display: inline-block;
}

.input-list-mail {
  width: 79%;
  display: inline-block;
}

.col-email {
  padding-left: 0px;
}

.delete-email {
  margin-left: auto;
  margin-right: auto;
  display: block;
}

.checkbox-array {
  display: inline-block;
  margin-right: 20px;
}

.general-form__tabs {
  margin: 0 0px 50px 0px;
}

.display_block {
  display: block;
}

.checkbox_size_m {
  margin-bottom: 0 !important;
}

.url-span {
  word-break: break-all;
}

.check-tabl {
  height: 22px;
}

.company-block{
  margin-top: 10px;
}
.mail-custom-col{
  overflow: auto;
  height: 80px;
}
.button-prod{
  display: block;
  margin: 20px 0;
}

.jodit_alfa {
  .jodit-wysiwyg {
    padding-left: 10em !important;
    padding-right: 5em !important;
  }
}

.buttonTable {
  margin-bottom: 25px;
}

.number-table {
  flex: 0 0 4.33333%;
}

.number-size {
  flex: 0 0 10.33333%;
}

.attach {
  display: block !important;
}

.attach__file, .attach__no-file {
  display: flex !important;
  margin: 0.5em;
}

//.icon-button_size_s {
//  width: 30px !important;
//  height: auto !important;
//}
