/* interface's main colors */
$dark_indigo: #0b1f35;
$dark_indigo_60: #6d7986;
$dark_indigo_30: #b6bcc3;
$dark_indigo_15: #dbdee1;
$dark_indigo_05: #f3f4f5;
$red_brand: #ef3124;
$red_brand_85: #f15045;
$red_error: #ff5c5c;
$green_done: #2fc26e;

$border_plate: #e8e8e8;
$border_menu: #0a1e32;