.dash-graph {
    width: 1092px;
    height: 420px;
    background: #FBFBFB;
    border-radius: 8px;
    margin-top: 24px;
}
.dash-legend-box {
    margin-top: 24px;
    height: 58px;
}
.dash-legend {
    display: inline-block;
    margin-right: 24px;
    width:  348px;
    height: 58px;
    background: #FBFBFB;
    border-radius: 8px;
}
.dash-legend-color {
    display: inline-block;
    margin-top: 20px;
    margin-left: 18px;
    width: 16px;
    height: 16px;
    -moz-border-radius: 8px;
    -webkit-border-radius: 8px;
    border-radius: 8px;
}
.dash-legend-title {
    display: inline-block;
    margin-top: 17px;
    margin-left: 16px;
    font-size: 16px;
}


.dashboard-total {
    width: 348px;
    height: 182px;
    background: #525E6C;
    border-radius: 8px;
    display: inline-block;
    margin-top: 20px;
    margin-right: 50px;

    &__number {
        margin-left: 60px;
        margin-top: 16px;

//        font-family: SF Pro Text;
        font-style: normal;
        font-weight: bold;
        font-size: 34px;
        line-height: 41px;
        letter-spacing: 0.37px;
        color: #FFFFFF;
    }
    &__numberPrev {
        margin-left: 50px;
        margin-top: 16px;
        padding-left: 20px;
        padding-top: 10px;

        width: 74px;
        height: 40px;
        left: 0px;
        top: 112px;

        background: #2FC26E;
        border-radius: 100px;

        font-style: normal;
        font-weight: normal;
        font-size: 15px;
        line-height: 20px;
        letter-spacing: -0.24px;
        color: #FFFFFF;
    }
}