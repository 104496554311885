.form-row {
  display: flex;
  padding: 15px 0;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  position: relative;

  &__cell {
    &.label {
      flex: 1;
      &.label--right {
        text-align: center;
      }
      &--absolute {
        position: absolute;
        top: 0;
      }
    }

    .input--label {
      display: block;
      padding: 10px;
    }

    &.input {
      flex: 2;
      display: flex;
      flex-direction: column;
      &--padding-left {
        padding-left: 25px;
      }

    }

    .input {
      &__group-checkbox {
        &-items {
          display: flex;
          flex-direction: column;
          padding: 10px 45px;

          label {
            padding: 5px 0;
          }
        }
      }
    }

    .input--item {
      display: flex;
      flex-direction: row;
      align-items: center;
      padding: 10px 10px;
      position: relative;

      justify-content: space-between;

      &__col {
        flex: 1;
        margin-right: 5px;
        &:last-child {
          margin-right: 0;
        }
      }

      &.lang {
        &_en {
          &::before {
            padding: 0 15px;
            content: 'EN';
            position: absolute;
            left: -40px;
            font-weight: 500;
          }
        }
        &_ru {
          &::before {
            padding: 0 15px;
            content: 'RU';
            position: absolute;
            left: -40px;
            font-weight: 500;
          }
        }
      }
    }
    .lb-lang {
      padding: 0 10px;
      font-weight: 400;
    }
  }

  &.btn-group {
    justify-content: flex-end;
  }
}

.radio_tecman {
  margin-right: 15px;
}